<template>
  <v-app>
    <v-main :class="viewClass">
      <router-view/>
    </v-main>
    <v-container class="pa-0">
      <v-snackbar 
      :timeout="5000" 
      v-model="snackbar.active"
      :color="snackbar.color">
        {{ snackbar.message}}

        <template v-slot:actions>
        </template>
      </v-snackbar>
    </v-container>
  </v-app>
</template>

<script>

import { mapGetters } from 'vuex';

export default {
  name: 'App',

  data: () => ({
    //
  }),
  computed: {
    ...mapGetters(['snackbar']), // Accedemos al estado del snackbar desde Vuex
    viewClass() {
      switch (this.$route.name) {
        case 'home':
          return 'bg-primary';
        case 'about':
          return 'about-background';
        case 'Login':
        case 'Change password':
        case 'Create password':
        case 'Recover password':
          return 'bg-primary';
        default:
          return 'bg-primary';
      }
    }
  }
}
</script>

<style scope>
v-main{
  height: 100%;
  overflow: hidden; 
}
.home-background {
  background-color: lightblue;
}
.about-background {
  background-color: lightgreen;
}
.login-background {
  background-color: var(--v-theme-primary);;
  
}
.default-background {
  background-color: var(--v-theme-secondary);;
}

router-view {
}

#app {
  height: 100%; /* Asegúrate de que el contenedor principal de Vue también ocupe el 100% */
}
html, body {
  overflow: hidden; /* Elimina el scroll en todo el documento */
  height: 100%;     /* Asegura que el contenido ocupe el 100% de la ventana */
  margin: 0;
  padding: 0;
}
</style>
